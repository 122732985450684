:root {

  --body-body-2-font-size: 14px;
  --body-body-2-font-style: normal;
  --body-body-2-font-weight: 400;
  --body-body-2-letter-spacing: 0px;
  --body-body-2-line-height: 24px;
  --brand-purple: rgba(140, 48, 245, 1);
  --brand-purple-100: rgba(241, 228, 255, 1);
  --default-black: rgba(11, 13, 23, 1);
  --default-white: rgba(255, 255, 255, 1);

  --heading-h3-font-size: 40px;
  --heading-h3-font-style: normal;
  --heading-h3-font-weight: 800;
  --heading-h3-letter-spacing: 0px;
  --heading-h3-line-height: 54px;

  --label-large-label-font-size: 16px;
  --label-large-label-font-style: normal;
  --label-large-label-font-weight: 500;
  --label-large-label-letter-spacing: 0px;
  --label-large-label-line-height: 24px;

  --label-medium-label-font-size: 14px;
  --label-medium-label-font-style: normal;
  --label-medium-label-font-weight: 500;
  --label-medium-label-letter-spacing: 0px;
  --label-medium-label-line-height: 20px;

  --label-small-label-font-size: 12px;
  --label-small-label-font-style: normal;
  --label-small-label-font-weight: 500;
  --label-small-label-letter-spacing: 0px;
  --label-small-label-line-height: 16px;

  --lead-lead-1-font-size: 18px;
  --lead-lead-1-font-style: normal;
  --lead-lead-1-font-weight: 400;
  --lead-lead-1-letter-spacing: 0px;
  --lead-lead-1-line-height: 32px;
  --mobile-mockup-shadow: 10px 15px 30px 0px rgba(41, 41, 42, 0.5);
  --navbar-shadow: 0px 1px 0px 0px rgba(229, 233, 242, 1);

  --subtitle-subtitle-2-font-size: 18px;
  --subtitle-subtitle-2-font-style: normal;
  --subtitle-subtitle-2-font-weight: 500;
  --subtitle-subtitle-2-letter-spacing: 0px;
  --subtitle-subtitle-2-line-height: 28px;
  --text-gray-100: rgba(244, 245, 247, 1);
  --text-gray-200: rgba(238, 239, 244, 1);
  --text-gray-300: rgba(217, 219, 225, 1);
  --text-gray-7: rgba(241, 242, 246, 1);
  --text-gray-900: rgba(24, 25, 31, 1);
}
